import React, { useEffect, useRef, useState } from 'react'
import tw from 'twin.macro'
import { useScrollPercentage } from 'react-scroll-percentage'
import { graphql, useStaticQuery } from 'gatsby'

import {
  CopyLGStyle,
  CopySMStyle,
  CopyStyle,
  HeaderLGStyle,
  HeaderStyle,
  SubheadStyle,
} from './UI'
import Flickity from 'react-flickity-component'

import iphoneBody from '../images/iphone-x-body.png'

import calendar from '../images/icons/feature-calendar.png'
import phone from '../images/icons/feature-phone.png'
import getPaid from '../images/icons/feature-get-paid.png'
import chooseRate from '../images/icons/feature-choose-rate.png'
import workers from '../images/icons/feature-workers.png'
import track from '../images/icons/feature-track.png'
import appscreen1 from '../images/app-screen-1.png'
import appscreen2 from '../images/app-screen-2.png'
import appscreen3 from '../images/app-screen-3.png'
import appscreen4 from '../images/app-screen-4.png'
import appscreen5 from '../images/app-screen-5.png'
import appscreen6 from '../images/app-screen-6.png'
import { GatsbyImage } from 'gatsby-plugin-image'

const Features = ({ }) => {
  const globalQuery = useStaticQuery(graphql`
    {
      contentfulGlobalSettings(pageId: { eq: "Globals" }) {
        appScreenshots {
          filename
          gatsbyImageData(placeholder: NONE)
          file {
            url
          }
        }
      }
    }
  `)

  const features = [
    {
      title: 'Pick your schedule',
      copy: "Using our intuitive platform, simply select the dates and times you’re available to work, or choose from a variety of available healthcare shifts that match your preferences.",
      image: appscreen1,
      icon: calendar,
    },
    {
      title: 'Apply on the go',
      copy: 'With the Staffy mobile app, securing your next healthcare shift is just a tap away. Whether you’re at home or on the move, find and apply for roles tailored to your expertise.',
      image: appscreen2,
      icon: phone,
    },
    {
      title: 'Get paid immediately',
      copy: 'Take control of how and when you get paid with instant payouts and receive your earnings within just minutes after completing your shifts. No more waiting days or weeks - with Staffy, your hard work is rewarded immediately.',
      image: appscreen3,
      icon: getPaid,
    },
    {
      title: 'Choose your rate',
      copy: 'With Staffy, we empower healthcare workers to leverage your skills, experience, and the demand in the healthcare industry to choose your own rates.',
      image: appscreen4,
      icon: chooseRate,
    },
    {
      title: 'Expand your network',
      copy: 'Staffy bridges the gap between dedicated healthcare workers like Nurses, Dieticians, and Personal Support Workers with leading healthcare organizations. Discover opportunities that match your qualifications.',
      image: appscreen5,
      icon: workers,
    },
    {
      title: 'Track your work',
      copy: 'With our user-friendly dashboard, review your work history, track the shifts you’ve completed, and see how much you’ve earned.',
      image: appscreen6,
      icon: track,
    },
  ]

  // Scroll related stuff
  const [currentFeature, setCurrentFeature] = useState(0)
  const [ref, percentage] = useScrollPercentage({
    threshold: 1 / 6,
  })

  useEffect(() => {
    const newIndex = Math.floor(percentage / 0.17)
    if (newIndex !== currentFeature) setCurrentFeature(newIndex)
  }, [percentage])

  const carousel = useRef(null)
  const [activeDotIndex, setActiveDotIndex] = useState(0)

  // Carousel related stuff
  useEffect(() => {
    carousel.current.flkty.on('change', (e) => {
      setActiveDotIndex(e)
    })
  }, [])

  const prevSlide = () => {
    carousel.current.flkty.previous()
  }

  const nextSlide = () => {
    carousel.current.flkty.next()
  }

  return (
    <section ref={ref} tw="py-10 lg:(py-0 relative h-[600vh])">
      {/* Desktop */}
      <div tw="mx-7 hidden lg:(grid max-w-screen-lg grid-cols-12 gap-x-6 mx-auto h-screen sticky top-0 py-[5vh])">
        {/* Left side */}
        <div tw="lg:(relative col-span-5 flex justify-center items-center)">
          <div tw="lg:(relative)">
            <img
              src={iphoneBody}
              tw="lg:(max-h-[calc(100vh - 10rem)])"
              alt=""
            />
            <img
              src={features[currentFeature].image}
              tw="lg:(absolute h-full w-full top-0 left-0 object-contain)"
              alt=""
            />
          </div>
        </div>
        {/* Right side */}
        <div tw="lg:(col-start-7 col-span-6 flex flex-col justify-start)">
          <h2 css={[HeaderLGStyle, tw`mb-7 lg:(mt-8 mb-6)`]}>
            Features of&nbsp;our&nbsp;app
          </h2>
          <p css={[CopyLGStyle]}>
            Staffy's innovative features that make it easier for you to work.
          </p>
          <div tw="flex lg:(mt-14)">
            <div tw="mr-6 h-[200px]">
              {features.map((feature, index) => (
                <div
                  key={`feature-${index}`}
                  css={[
                    tw`border-l border-black h-1/6`,
                    currentFeature === index && tw`border-[#FF4D00]`,
                  ]}
                />
              ))}
            </div>
            <div tw="flex">
              <div tw="w-14 mr-4">
                <img tw="w-full" src={features[currentFeature].icon} />
              </div>
              <div tw="flex-1">
                <h3 css={[HeaderStyle, tw`text-[#FF4D00] mb-6`]}>
                  {features[currentFeature].title}
                </h3>
                <p css={[CopyStyle, tw`mb-6`]}>
                  {features[currentFeature].copy}
                </p>
                <div css={[CopySMStyle]}>{currentFeature + 1}/6</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Mobile */}
      <div tw="overflow-x-hidden lg:(hidden)">
        <div tw="mx-7">
          <h2 css={[HeaderStyle, tw`mb-7 text-center`]}>Features of our app</h2>
          <p css={[CopyLGStyle, tw`text-center`]}>
            Staffy's innovative features that make it easier for you to work.
          </p>
        </div>
        <Flickity
          className="carousel"
          ref={carousel}
          options={{
            prevNextButtons: false,
            pageDots: false,
            cellAlign: 'left',
            resize: true,
            wrapAround: false,
          }}
          tw="mt-8"
        >
          {features.map((feature, index) => (
            <div key={`testimonial-${index}`} tw="w-full">
              {/* Phone mockup */}
              <div tw="w-full h-[375px] overflow-hidden bg-[#F1FAFD]">
                <div tw="relative h-full -bottom-14">
                  <img
                    src={iphoneBody}
                    tw="h-full w-full top-0 left-0 object-contain"
                  />
                  <img
                    src={features[index].image}
                    tw="absolute h-full w-full top-0 left-0 object-contain"
                  />
                </div>
              </div>
              {/* Copy */}
              <div tw="mx-7 pt-8 pb-4 text-center">
                <div tw="mx-auto w-14 mb-4">
                  <img tw="w-full" src={features[index].icon} />
                </div>
                <h3 css={[SubheadStyle, tw`text-[#FF4D00] mb-6`]}>
                  {features[index].title}
                </h3>
                <p css={[CopyStyle]}>{features[index].copy}</p>
              </div>
            </div>
          ))}
        </Flickity>
        {/* Flickity controls */}
        <div>
          {/* Dots */}
          <div tw="w-[200px] mx-auto relative">
            {features.map((slide, index) => (
              <hr
                key={`dot-${index}`}
                css={[
                  tw`inline-block border-[#202C3A]`,
                  `width: calc(100% / ${features.length})`,
                  activeDotIndex === index && tw`border-[#FF4D00]`,
                ]}
              />
            ))}
          </div>
          {/* Arrows */}
          <div tw="mt-2 flex justify-center">
            <button tw="mx-2" onClick={prevSlide}>
              Prev
            </button>
            <button tw="mx-2" onClick={nextSlide}>
              Next
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Features
